import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
const TOKEN_KEY = 'auth-token'

@Injectable({
  providedIn: 'root'
})
export class RestService {

  baseUrl: string = `${environment.baseUrl}`;
  headers: any;
  accessToken : string ='';
  constructor(private httpClient: HttpClient,private storage: Storage,
    private msal:MsalService,
    private router:Router) {
    // this.storage.get('auth-token').then((result) => {
    //   this.accessToken= 'vinod';
    //   this.headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': this.accessToken });
    // })
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': localStorage.getItem('accessToken') });
  
  }

  /**
* Get data from the REST API 
* map the result to return only the results that we need
* 
* @param {string} method Name of method in API
* @param {queryString} query value in the get API
* @returns Observable with the response
*/
  public getData(method: string, queryString: string): Observable<any> {
    // this.storage.get('auth-token').then((result) => {
    //   this.accessToken = result+'vinod';
    //   this.headers['Authorization'] = this.accessToken;
    // })
    this.headers['Authorization'] = localStorage.getItem('accessToken');
    return this.httpClient
    .get(this.baseUrl + method + '/' + queryString, { headers: this.headers }).pipe(
      map(res => {
        return res;
      }),
      catchError(error=>{
        if (error instanceof HttpErrorResponse) {
          console.error('error status = ', error.status);
          // console.log('---> filter:', req.params.get('filter'));
          
          if (error.status === 401) {
            // redirect to error page
            // OR you can use a toast
            console.error('redirect');
            this.redirectUserForToken();
           // window.location.reload();
          //this.router.navigate(['login'], { replaceUrl: true });
          }
        }
        return throwError(error);
      })
    );
    // return this.httpClient
    //   .get(this.baseUrl  + method + '/' + queryString, { headers: this.headers })
  }

  /**
 * Post data to the REST API 
 * map the result to return only the results that we need
 * 
 * @param {string} method Name of method in API
 * @param {queryData} postDataValeu value in the get API
 * @returns Observable with the response
 */

  public postData(method: string, queryData: any): Observable<any> {
    // this.storage.get('auth-token').then((result) => {
    //   this.accessToken = result+'VINOD';
    //   this.headers['Authorization'] = this.accessToken+'vinod';
    // })
    this.headers['Authorization'] = localStorage.getItem('accessToken');
    return this.httpClient
    .post(this.baseUrl + method, queryData, { headers: this.headers }).pipe(
      map(res => {
        return res;
      }),
      catchError(error=>{
        if (error instanceof HttpErrorResponse) {
          console.error('error status = ', error.status);
          // console.log('---> filter:', req.params.get('filter'));
          
          if (error.status === 401) {
            // redirect to error page
            // OR you can use a toast
            console.error('redirect');
            this.redirectUserForToken();
           // window.location.reload();
          //this.router.navigate(['login'], { replaceUrl: true });
          }
        }
        return throwError(error);
      })
    );
  }

  private errorHandler(error: HttpErrorResponse) {
    // To know weather the error is from Client or server error
    if (error.error instanceof ErrorEvent) {
      console.log('Client side error. Please check the request and body');
    } else {
      console.log('Server Error. Please check the error from server side', error);
      if (error.status === 401) {
        this.redirectUserForToken();
       // this.router.navigate(['login'], { replaceUrl: true });
      }

      // if (error.status === 403) {
      //     // window.location.reload();
      //     this.logOutUser();

      // }
    }
    // get's error message from errors.json.ts
   
    return 'Server Side Error'
  }

  redirectUserForToken(){
    this.msal.instance.acquireTokenSilent(environment.adalConfig).then(data=>{
      this.accessToken = data.accessToken;
     
      localStorage.setItem('accessToken','Bearer ' + data.accessToken);
      this.storage.set(TOKEN_KEY, 'Bearer ' + data.accessToken).then(() => {
       
      });
      window.location.reload();
    })
  }

}
