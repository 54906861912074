export class AppSettings {
   public static USER_INFO = 'user-info';
   public static USER_ID = 'user-id';
   public static BUID = 'bu-id';
   public static CNF_STORAGE = 'cnf-storage-status';
   public static STATUS_DRAFT = '1';
   public static STATUS_PENDING = '2';
   public static STATUS_APPROVED = '5';
   public static STATUS_CSP = '4';
   public static STATUS_REJECTED = '6';
   public static STATUS_SPM_PENDING = '8';
   public static STATUS_DISPATCH = '7';   
   public static STATUS_FULLY_DISPATCH = '9';  
   public static STATUS_ADMIN_PENDING = '21';  
   public static ROLE_BM = '1';
   public static ROLE_DM = '2';
   public static ROLE_PM = '3';
   public static ROLE_SPM = '4';
   public static ROLE_LOGI = '5';
   public static ROLE_FIN = '6';
   public static ROLE_ADMIN = '7';


   //'---------Indent return Datta -- 25.06.2019  
   public static STATUS_Pending_for_Finance_Approval = '12';   
   public static STATUS_Pending_for_NSH_Approval = '13';   
   public static STATUS_Pending_for_Business_Controller_Approval = '14';   
   public static STATUS_Approved_Not_Received = '15';   
   public static STATUS_Partially_Received = '16';   
   public static STATUS_Fully_Received = '17';  

   public static STATUS_Pending_from_BM = '18';   
   public static STATUS_Pending_from_DM = '19'; 
   public static STATUS_Pending_from_Supplier = '20';    
    
   public static ROLE_TM = '8';
   public static ROLE_BCS = '9'; //----------Business Controller, Seeds
   public static ROLE_NSH = '10'; //----------National Head, Field Crops
   public static ROLE_BC = '11'; //----------Business Controller


   public static base64Header = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";

}